@import '/src/assets/css/fonts.css';

@import "~bootstrap/scss/_functions.scss";

@function tinted-color-contrast($value) {
    @return color-contrast($value, $color-contrast-dark: darken($color: $value, $amount: 50%), $color-contrast-light: lighten($color: $value, $amount: 60%));
}

@mixin always-box-shadow($shadow...) {
    $result: ();

    @each $value in $shadow {
    @if $value != null {
        $result: append($result, $value, "comma");
    }
    @if $value == none and length($shadow) > 1 {
        @warn "The keyword 'none' must be used as a single argument.";
    }
    }

    @if (length($result) > 0) {
    box-shadow: $result;
    }
}

@import './bootstrap-custom/variables';                        /* THEME OVERRIDES */
@import "~bootstrap/scss/_variables";      /* ORIGINAL */
@import "~bootstrap/scss/_mixins";         /* ORIGINAL */
@import "./bootstrap-custom/mixins.scss";   /* THEME OVERRIDES */
@import "~bootstrap/scss/_utilities";

/* COMPONENTS */
@import "~bootstrap/scss/_root";
@import "~bootstrap/scss/_reboot";
@import "~bootstrap/scss/_type";
@import "~bootstrap/scss/_images";
@import "~bootstrap/scss/_containers";
@import "~bootstrap/scss/_grid";