@import "../../assets/bootstrap.scss";

.Header {
    margin: 3rem auto;
    text-align: center;
    position: relative;
    z-index: 3;
    left: 0;
    top: 0;

    &::before {
        display: block;

        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: auto;
        aspect-ratio: 1;
        z-index: -1;
        background: url("./Circle.png") no-repeat center;
        animation: HeaderBG forwards 2.5s linear, HeaderBGRepeat infinite 1.5s*32 linear;
        animation-delay: 1.5s, 4s;
        background-size: contain;
        height: 100%;
        translate: -50% -50%;
        transform: rotate(-45deg);
        scale: .95;
        opacity: 0;
    }

    &::after {
        display: block;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba($black, 1);
        animation: HeaderBGAfter forwards 3s ease-out;
        animation-delay: 1s;

    }

    animation: HeaderDiv forwards 3s ease-in-out;
}

.FadeInAfter {
    opacity: 0;
    animation: ContentOpacity forwards 1s ease-in-out;
    animation-delay: 3s;
}

@keyframes HeaderDiv {
    from { top: 16px }
    to { top: 0 }
}
@keyframes ContentOpacity {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes HeaderBG {
    from {
        transform: rotate(-45deg);
        scale: .95;
        opacity: 0;
    }
    to {
        transform: rotate(0deg);
        scale: 1;
        opacity: 1;
    }
}

@keyframes HeaderBGRepeat {
    from {
        transform: rotate(0);
    } to {
        transform: rotate(365deg);
    }
}

@keyframes HeaderBGAfter {
    from {
        background-color: rgba($black, 1.0);
    }
    to {
        background-color: rgba($black, 0.0);
    }
}

.SectionContainer {

    .Section {
        max-width: 800px;
        padding: 2rem;
        margin: 2rem auto;
        border-bottom: .5px solid rgba($gray-300, 0.5);
        &:last-child {
            border-bottom: none;
        }
    }

}