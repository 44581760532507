@import "../../assets/bootstrap.scss";

.footer {
    background-color: $light;
    color: $gray-500;
    padding: 2rem 0;
    font-size: 90%;

    &.dark {
        background-color: $black;
    }

    button, a {
        padding: .25rem 0;
        color: $gray-500;
        border-bottom: 1px solid $gray-500;
        border-radius: 0;
        padding: .125rem 0;
        margin: .05125rem 0; // 125rem
        &:hover {
            color: $primary;
            border-bottom-color: $primary;
        }
    }
}