@import './bootstrap.scss';

@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";

/* COMPONENT OVERRIDE */
@import "./bootstrap-custom/alert";
@import "./bootstrap-custom/buttons";
@import "./bootstrap-custom/card";

.modal-content {
    background-color: $white;
    background-image: url("./img/noise-texture.png");
    background-position: center;
    background-repeat: repeat;
    background-blend-mode: darken;
}

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";

html, body, #root {
    min-height: 100%;
}

.navbar-nav {
    .nav-link {
        color: $dark;
        font-weight: 500;
        border-radius: .25rem;
        padding-top: .25rem;
        padding-bottom: .25rem;
        margin: 0 .25rem;
        &.active, &:hover {
            color: $dark;
            background-color: rgba($primary, 0.2);
        }
    }
}