@import '../../assets/bootstrap.scss';

.profile-carousel {
    display: grid;
    grid-template-rows: 1fr;
    gap: 16px;
    
    .profile-carousel-item {
        padding: 1rem;
        background: rgba($light, 0);
        display: flex;
        flex-flow: column;
        align-content: center;
        border-radius: 1rem;
        transition: all 500ms;// cubic-bezier(0.175, 0.885, 0.32, 1.275);

        &:not(.loading):not(.no-hover):hover {
            scale: 1.0125;
            background: rgba($light, 1);
            box-shadow: 0 0 8px rgba($dark, 0.25);
            .profile-carousel-img {
                scale: 1.025;
                box-shadow: 0 0 25px rgba($dark, 0.5),
                            0 0 0 4px rgba($primary,1) inset;
            }
        }

        &.loading {
            background-color: rgba(0,0,0, 0.01);
            animation-name: loading;
            animation-duration: 1s;
            animation-iteration-count: infinite;

            -moz-user-select: none;
            -webkit-user-select: none;
            user-select: none;


            .profile-carousel-img {
                background-color: rgba(0,0,0, 0.01);
                animation-name: loading;
                animation-duration: 1s;
                animation-iteration-count: infinite;
                // width: 230px;
            }

            
            @keyframes loading {
                0% {
                    background-color: rgba($gray-500, 0.01);
                }
                95% {
                    background-color: rgba($gray-500, 0.075);
                }
                100% {
                    background-color: rgba($gray-500, 0.01);
                }
            }
        }

        .profile-carousel-img {
            aspect-ratio: 1;
            transition: all 500ms;
            width: 100%;
            max-width: 230px;
            background-size: cover;
            background-position: center center;
            //rounded-circle overflow-hidden position-relative mx-auto mb-4
            border-radius: 100%;
            overflow: hidden;
            position: relative;
            margin: 0 auto 1rem auto;
            user-select: none;
        }

        .profile-carousel-description {
            text-align: center;
            display: block;
        }
        .profile-carousel-link {
            text-align: center;
            padding: .5rem 1rem;
            margin: 0;
        }
    }
}