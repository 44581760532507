$button-always-box-shadows: (
  0 1px 1px 0 rgba($gray-800, 0.05),
  0 5px 2px -2px rgba(#FFF, 0.05) inset
);

@mixin button-variant(
  $background,
  $border: $background,
  $color: color-contrast($background),
  // FIXME:
  $hover-background: lighten($background, 10%), //if($color == $color-contrast-light, shade-color($background, $btn-hover-bg-shade-amount), tint-color($background, $btn-hover-bg-tint-amount)),
  $hover-border: $hover-background,
  $hover-color: tinted-color-contrast($hover-background),
  $active-background: darken($background, 10%), //if($color == $color-contrast-light, shade-color($background, $btn-active-bg-shade-amount), tint-color($background, $btn-active-bg-tint-amount)),
  $active-border: $active-background,
  $active-color: tinted-color-contrast($active-background),
  $disabled-background: $background,
  $disabled-border: $border,
  $disabled-color: tinted-color-contrast($disabled-background)
) {
  color: $color;
  @include gradient-bg($background);
  border-color: $border;
  @include box-shadow($btn-box-shadow);
  @include always-box-shadow($button-always-box-shadows...);

  &:hover {
    color: $hover-color;
    @include gradient-bg($hover-background);
    border-color: $hover-border;
  }

  .btn-check:focus + &,
  &:focus {
    color: $hover-color;
    @include gradient-bg($hover-background);
    border-color: $hover-border;
    @if $enable-shadows {
      @include box-shadow($btn-box-shadow, 0 0 0 $btn-focus-width rgba(mix($color, $border, 15%), .5));
    } @else {
      // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: 0 0 0 $btn-focus-width rgba(mix($color, $border, 15%), .5);
    }
  }

  .btn-check:checked + &,
  .btn-check:active + &,
  &:active,
  &.active,
  .show > &.dropdown-toggle {
    color: $active-color;
    background-color: $active-background;
    // Remove CSS gradients if they're enabled
    background-image: if($enable-gradients, none, null);
    border-color: $active-border;

    &:focus {
      @if $enable-shadows {
        @include box-shadow($btn-active-box-shadow, 0 0 0 $btn-focus-width rgba(mix($color, $border, 15%), .5));
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: 0 0 0 $btn-focus-width rgba(mix($color, $border, 15%), .5);
      }
    }
  }

  &:disabled,
  &.disabled {
    color: $disabled-color;
    background-color: $disabled-background;
    // Remove CSS gradients if they're enabled
    background-image: if($enable-gradients, none, null);
    border-color: $disabled-border;
    opacity: 0.5;
  }
}

@mixin button-outline-variant(
  $color,
  $color-hover: $color,
  $active-background: tint-color($color, 80%),
  $active-border: $gray-200,
  $active-color: $color
) {
  color: $color;
  border-color: $active-border;
  background-color: $white;
  @include always-box-shadow($button-always-box-shadows...);

  &:hover {
    color: $color-hover;
    background-color: $active-background;
    border-color: $active-border;
  }

  .btn-check:focus + &,
  &:focus {
    @include always-box-shadow($button-always-box-shadows..., 0 0 0 $btn-focus-width rgba($color, .5));
  }

  .btn-check:checked + &,
  .btn-check:active + &,
  &:active,
  &.active,
  &.dropdown-toggle.show {
    color: $active-color;
    background-color: $active-background;
    border-color: $active-border;

    &:focus {
      @if $enable-shadows {
        @include box-shadow($button-always-box-shadows..., $btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($color, .5));
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        @include always-box-shadow($button-always-box-shadows..., 0 0 0 $btn-focus-width rgba($color, .5));
      }
    }
  }

  &:disabled,
  &.disabled {
    opacity: .5;
  }
}

