@import "../../assets/bootstrap.scss";

#about, #offers, #membership, #partners, #faq, #team {
    min-height: 100vh; // calc(100vh - 56px);
    display: flex;
    align-items: center;
    padding: max(1rem, 120px) 1rem;
}

#home {
    background: linear-gradient( -27.5deg, $orange 0%, $yellow 100%);
    background-repeat: repeat;
    background-position: center;
    color: $gray-900;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

#team {
    display: block;
}

.bg-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    z-index: 0;
    height: 100%;
    width: 100%;

}
.bg-overlay-notes {
    background-image: url("../../assets/img/notes_overlay.png");
}

#about {
    justify-content: center;
    background-color: $light;
}

#offers {
    background: linear-gradient( -27.5deg, $indigo 0%, $purple 100%);
}

#partners {
    justify-content: center;
}
#membership {
    background: linear-gradient(45deg, $orange 0%, $yellow 100%);
}

.btn-nav {
    $main: $primary;
    $shade: $primary;
    background-color: rgba($main, 0);
    color: $shade;
    border-color: $shade;
    &:hover {
        $shade: darken($main, 10%);
        background-color: rgba($main, 0.3);
        border-color: $shade;
        color: $shade;
    }
}
.btn-cta {
    $main: $danger; //$primary;
    $shade: $danger; //darken($main, 20%);
    background-color: rgba($main, 0);
    color: $shade;
    border-color: $shade;
    &:hover {
        $shade: darken($main, 10%);
        background-color: rgba($main, 0.3);
        border-color: $shade;
        color: $shade;
    }
}

.btn-cta, .btn-nav {
    text-transform: uppercase;
}